import { Link } from 'gatsby'
import React from 'react'

import styles from './intro.module.css'

const Intro = ({ siteTitle }) => (
    <div className={styles.root}>
        {/* <h1 hidden>Welcome to {siteTitle}</h1> */}
        <p className={styles.title}>
            Co-Founder / CTO / Full-Stack Engineer at Piing. Manchester, UK.
        </p>
        <p>
            For more than a decade, I've had the pleasure of creating digital interactive experiences at <a href="https://lovecreative.com">LOVE.</a> and <a href="https://rhythm.digital">Rhythm</a>,
            for some of the biggest brands in the world. A handful of these include Sony PlayStation, Umbro, Diageo, Moët & Chandon, The V&A, Manchester City F.C. and Deutsche Bank. Now Co-Founder at <a href="https://piing.events">Piing</a>, our event tech startup.
        </p>
    </div>
)

export default Intro


